import React from 'react'
import Layout from '../components/Layout'

export default function about() {
    return (
        <Layout>
            <div className="container p-6">
                <h1 className="title has-text-black">About this website</h1>
                <p>This website is a work-in-progress.</p>
            </div>
        </Layout>
    )
}
